import {isEqual} from 'lodash';

interface ShapeItem {
    ty: string;
    c?: {k: number[]};
    it?: ShapeItem[];
}

interface Shape {
    ty: string;
    it: ShapeItem[];
}

interface Layer {
    shapes?: Shape[];
}

interface AnimationData {
    layers?: Layer[];
}

export const changeColors = (
    animationData: AnimationData,
    primary: number[],
    secondary: number[]
): AnimationData => {
    // This is green color replace this if ever need be .098 .5294 .3294 1
    if (animationData.layers && Array.isArray(animationData.layers)) {
        animationData.layers.forEach((layer) => {
            if (layer.shapes && Array.isArray(layer.shapes)) {
                layer.shapes.forEach((shape) => {
                    if (shape.ty === 'gr' && shape.it) {
                        shape.it.forEach((shapeItem) => {
                            if (shapeItem.ty === 'fl' && shapeItem.c) {
                                // secondary color replacement
                                if (
                                    isEqual(
                                        shapeItem.c.k,
                                        [0.2035, 0.4576, 0.8965, 1]
                                    )
                                ) {
                                    shapeItem.c.k = secondary;
                                }
                            }

                            if (shapeItem.ty === 'st' && shapeItem.c) {
                                // primary color replacement
                                if (
                                    isEqual(
                                        shapeItem.c.k,
                                        [0.1255, 0.2627, 0.5059, 1]
                                    )
                                ) {
                                    shapeItem.c.k = primary;
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    return animationData;
};
